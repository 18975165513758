import React from "react";

import {
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";

import useLocalization from "#src/hooks/useLocalization";

import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useListEquipmentTypes } from "#hooks/adapters/useEquipment";

export const EquipmentFilterPanel = ({
  filterConfigStorageKey,
  isWithCustomAttributes = true,
}: {
  isWithCustomAttributes?: boolean;
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();

  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];

  const { customAttributeFilters } = useCustomAttributeFilters({
    assetType: AssetType.EQUIPMENT,
    enabled: isWithCustomAttributes,
  });

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Filters"]}
      filters={[
        {
          component: (
            <TextInput
              name="name"
              placeholder={`Search ${localize("equipment_plural")}...`}
              type="search"
              isInline
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              label={`${localize("Equipment")} Type`}
              name="type.id"
              options={equipmentTypes}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <FacilityDropdownInput
              key="facility.id"
              name="facility.id"
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        ...(isWithCustomAttributes ? customAttributeFilters : []),
      ]}
    />
  );
};
