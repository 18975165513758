import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
} from "./DomainModelSchemas";
import { TemplatedConfigurationSchema } from "./TemplatedConfigurationSchemas";
import { ResourceZodSchema } from "../util";

export const TemplatedConfigurationResourceStatus = {
  SUBMITTED: "submitted",
  SUCCESS: "success",
  FAILED: "failed",
} as const;

export const TemplatedConfigurationRunStatus = {
  SUBMITTED: "submitted",
  SUCCESS: "success",
  FAILED: "failed",
  PARTIAL: "partial",
} as const;

export const TemplateInputValuesSchema = z.record(
  z.string(),
  z.union([z.string(), z.number(), z.boolean(), z.null()])
);

export const TemplateCustomAttributeInputSchema = z.record(
  z.string(),
  z.record(
    z.string(),
    z.union([
      z.string(),
      z.array(z.string()),
      z.number(),
      z.array(z.number()),
      z.boolean(),
      z.null(),
    ])
  )
);

export const TemplateCustomAttributeConfigurationSchema = z.record(
  z.string(),
  z.object({ entity_subtype: z.string() })
);

export const TemplatedConfigurationRunResource = z.object({
  template_resource_id: z.string(),
  templated_configuration_run_id: z.string(),
  status: z.enum([
    TemplatedConfigurationResourceStatus.SUBMITTED,
    TemplatedConfigurationResourceStatus.SUCCESS,
    TemplatedConfigurationResourceStatus.FAILED,
  ]),
  name: z.string().nullish(),
  type: ResourceZodSchema.nullish(),
  id: z.string().nullish(),
  errors: z.record(z.string(), z.any()),
  is_primary: z.boolean(),
});

export const TemplatedConfigurationRun = z
  .object({
    id: z.string(),
    templated_configuration_name: z.string(),
    company_id: z.string(),
    inputs: TemplateInputValuesSchema,
    custom_attribute_inputs: TemplateCustomAttributeInputSchema,
    custom_attribute_configuration: TemplateCustomAttributeConfigurationSchema,
    status: z.enum([
      TemplatedConfigurationRunStatus.SUBMITTED,
      TemplatedConfigurationRunStatus.SUCCESS,
      TemplatedConfigurationRunStatus.PARTIAL,
      TemplatedConfigurationRunStatus.FAILED,
    ]),
    results: z.record(z.string(), z.any()),
  })
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema);

export const TemplatedConfigurationRunSchema = z
  .object({
    resources: z.array(TemplatedConfigurationRunResource),
    templated_configuration: TemplatedConfigurationSchema.pick({
      name: true,
      display_name: true,
      primary_resource_type: true,
    }),
  })
  .merge(TemplatedConfigurationRun);

export const TemplatedConfigurationRunRequestSchema =
  TemplatedConfigurationRun.pick({
    inputs: true,
    custom_attribute_inputs: true,
    custom_attribute_configuration: true,
  }).describe(
    "Schema for creating a new Templated Configuration Run resource type"
  );

export type TemplatedConfigurationRunRequestType = z.infer<
  typeof TemplatedConfigurationRunRequestSchema
>;
export type TemplatedConfigurationRunType = z.infer<
  typeof TemplatedConfigurationRunSchema
>;
