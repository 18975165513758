import {
  ResourceDefinitions,
  ResourceType,
  TemplatedConfigurationInputType,
  TemplatedConfigurationType,
} from "@validereinc/domain";
import { useMemo } from "react";

export const useFindMainResourceIdFromTemplatedConfiguration = ({
  templatedConfiguration,
  primaryResourceType,
}: {
  templatedConfiguration?: TemplatedConfigurationType;
  primaryResourceType?: ResourceType;
}) =>
  useMemo(() => {
    if (!templatedConfiguration) return;
    return Object.keys(
      templatedConfiguration.configuration.custom_attribute_configuration
    ).find(
      (resourceId) =>
        templatedConfiguration.configuration.custom_attribute_configuration[
          resourceId
        ].entity_type === primaryResourceType
    );
  }, [templatedConfiguration]);

/**
  This hook takes the templated configuration, primary resource type, and input schema
  and returns the following format:
      [
        {
          resourceId: "equipment1",
          resourceType: Resources.EQUIPMENT,
          title: "Equipment",
          fields: {field1: {<custom field definition>}, field2: {<custom field definition>}},
        },
        ...
      ]
   */
export const useCustomAttributeSectionsDefinition = ({
  templatedConfiguration,
  primaryResourceType,
  inputSchema,
}: {
  templatedConfiguration?: TemplatedConfigurationType;
  primaryResourceType?: ResourceType;
  inputSchema?: TemplatedConfigurationInputType;
}) =>
  useMemo(() => {
    if (!templatedConfiguration) return [];

    /**
    This variable has the following structure:
      {
          equipment1: Resources.EQUIPMENT,
          flow1: Resources.FLOW,
          flow2: Resources.FLOW,
      }
    */

    const resourceIdToResourceType = Object.entries(
      templatedConfiguration.configuration.custom_attribute_configuration
    ).reduce(
      (prev, [resourceId, { entity_type }]) => {
        return {
          ...prev,
          [resourceId]: entity_type,
        };
      },
      {} as Record<string, ResourceType>
    );

    /**
    This variable has the following structure:
      {
          Resources.EQUIPMENT: ["equipment1"],
          Resources.FLOW: ["flow1", "flow2"],
      }
    */

    const resourceTypeToResourceIds = Object.entries(
      templatedConfiguration.configuration.custom_attribute_configuration
    ).reduce(
      (prev, [resourceId, { entity_type }]) => {
        return {
          ...prev,
          [entity_type]: [...(prev[entity_type] ?? []), resourceId],
        };
      },
      {} as Partial<Record<ResourceType, string[]>>
    );

    /**
    This variable has the following structure:
      {
        flow1: "Flow 1",
        flow2: "Flow 2",
        equipment1: "Equipment"
      }

    For each resource type, singular label of resource is used as the title with numbers (if there's more than one)
   */

    const resourceIdToSectionHeader = Object.entries(
      resourceTypeToResourceIds
    ).reduce(
      (prev, [resourceType, resourceIds]: [string, string[]]) => {
        const resolvedEntityName =
          ResourceDefinitions[resourceType as ResourceType].label.singular;
        const resourceTypeHeaders = resourceIds.reduce(
          (prevR, resource_id, index) => {
            prevR[resource_id] =
              resourceIds.length > 1
                ? `${resolvedEntityName} ${index + 1}`
                : resolvedEntityName;
            return prevR;
          },
          {} as Record<string, string>
        );
        return { ...prev, ...resourceTypeHeaders };
      },
      {} as Record<string, string>
    );

    const sections = Object.keys(inputSchema?.custom_attribute_inputs ?? {})
      .map((resourceId: string) => {
        const fields = inputSchema?.custom_attribute_inputs?.[resourceId] ?? {};
        return {
          resourceId,
          resourceType: resourceIdToResourceType[resourceId],
          title: resourceIdToSectionHeader[resourceId],
          fields,
        };
      })
      .filter(
        (sectionDefinition) =>
          Object.keys(sectionDefinition.fields ?? {}).length > 0
      );

    // If there's there's a primary resource type available, move those sections to the top:
    if (primaryResourceType) {
      sections.sort((a, b) =>
        resourceIdToResourceType[a.resourceId] === primaryResourceType
          ? -1
          : resourceIdToResourceType[b.resourceId] === primaryResourceType
            ? 1
            : 0
      );
    }
    return sections;
  }, [templatedConfiguration, primaryResourceType, inputSchema]);
