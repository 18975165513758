import { AddRecordDialog } from "#batteries-included-components/Dialogs/AddRecordDialog";
import {
  useEnhanceRecordResources,
  useListRecords,
  useRecordMeasurementTypeSpecificTableHeader,
} from "#hooks/adapters/useRecords";
import { FLOW_ATTRIBUTES, useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import BulkRecordStatusChangeButton from "#src/batteries-included-components/Buttons/BulkRecordStatusChangeButton/BulkRecordStatusChangeButton";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import { RecordValueDrawer } from "#src/batteries-included-components/Drawers/RecordValueDrawer";
import { useRecordValueDrawer } from "#src/batteries-included-components/Drawers/RecordValueDrawer/RecordValueDrawer.helper";
import {
  formatStatusFilter,
  getExportGroupBy,
  getGroupBy,
  getIngestionKey,
  useDataIngestionMapping,
} from "#src/batteries-included-components/Panels/TablePanels/RecordsListTablePanel.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import InlineRecordValueStatusPicker from "#src/components/Common/InlineRecordValueStatusPicker/InlineRecordValueStatusPicker";
import { RecordValueCell } from "#src/components/Common/RecordValueCell/RecordValueCell";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import {
  useApplyDefaultRecordConfiguration,
  useApplyDefaultRecordConfigurationAsync,
} from "#src/queries/recordQueries";
import { linkToAssetDetailPage, linkToRecordDetail } from "#src/utils/links";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  HeaderType,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import type {
  AssetTypeType,
  DefaultConfigurationFilterType,
  RecordType,
} from "@validereinc/domain";
import { AssetType, RecordDomain, SortDirection } from "@validereinc/domain";
import {
  datetimeFormatter,
  downloadLink,
  fromUTC,
  monthFormatter,
  toFlattenedObject,
  toStartCaseString,
  yearMonthFormatter,
  yearMonthParser,
} from "@validereinc/utilities";
import parseISO from "date-fns/parseISO";
import React, { useEffect, useState } from "react";
import { ASYNC_THRESHOLD } from "./CalculationsTablePanel/CalculationsTablePanel.helper";

const sorting: SortingType = {
  sortBy: "year_month",
  sortDirection: SortDirection.DESCENDING,
};

export const RecordsListTablePanel = ({
  type,
  filterConfigStorageKey,
  tableConfigStorageKey,
  applyAutomationStorageKey,
  setAvailableProperties,
}: {
  type: AssetTypeType;
  applyAutomationStorageKey: string;
  setAvailableProperties?: (keys: string[]) => void;
} & StorageKeys) => {
  const { getTypeName } = useMeasurementTypes();
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const { localize } = useLocalization();
  const [rawFilters] = useFilters(filterConfigStorageKey);
  const {
    reporting_group_id,
    ["flow.status"]: flowStatus,
    ["equipment.status"]: equipmentStatus,
    ["facility.status"]: facilityStatus,
    ["equipment.facility.status"]: equipmentFacilityStatus,
    measurement_type: measurement_type_filter,
    // savedFilter key should not be sent for fetching the list:
    savedFilter: _,
    ["year_month.from"]: yearMonthFrom,
    ["year_month.to"]: yearMonthTo,
    ...restFilters
  } = toFlattenedObject(rawFilters);
  const [showBulkApplyWarning, setShowBulkApplyWarningWarning] =
    useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    rawFilters
  );

  const isPropertyFilterEnabled = !!measurement_type_filter;

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState({});

  const applyAutomation = useApplyDefaultRecordConfiguration();
  const applyAutomationAsync = useApplyDefaultRecordConfigurationAsync(
    applyAutomationStorageKey
  );
  const assetGroupBy = getGroupBy(type);
  const exportGroupBy = getExportGroupBy(type);
  const dataIngestionKey = getIngestionKey(type);
  const dataIngestionResource = useDataIngestionMapping(type);

  const minYearMonth = yearMonthFrom
    ? yearMonthFormatter(fromUTC(parseISO(yearMonthFrom)))
    : yearMonthFormatter(DEFAULT_DATE_RANGES.lastWholeMonth.from);
  const maxYearMonth = yearMonthTo
    ? yearMonthFormatter(fromUTC(parseISO(yearMonthTo)))
    : yearMonthFormatter(DEFAULT_DATE_RANGES.lastWholeMonth.to);

  const recordParams: Parameters<typeof RecordDomain.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    groupBy: assetGroupBy,
    includeAssetDetail: true,
    filters: {
      ...toFlattenedObject(restFilters),
      reporting_group_id: { $exact: reporting_group_id },
      ["flow.status"]: formatStatusFilter(flowStatus),
      ...(equipmentStatus
        ? { ["equipment.status"]: formatStatusFilter(equipmentStatus) }
        : {}),
      ...(facilityStatus
        ? { ["facility.status"]: formatStatusFilter(facilityStatus) }
        : {}),
      ...(equipmentFacilityStatus
        ? {
            ["equipment.facility.status"]: formatStatusFilter(
              equipmentFacilityStatus
            ),
          }
        : {}),
      asset_type: { $exact: type },
      $and: [
        {
          year_month: {
            $gte: minYearMonth,
          },
        },
        {
          year_month: {
            $lte: maxYearMonth,
          },
        },
      ],
    },
  };

  const exportRecordParams: Parameters<typeof RecordDomain.exportList>[0] = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    groupBy: exportGroupBy,
    filters: recordParams.filters,
  };

  const isRecordQueryEnabled = !!reporting_group_id;

  const recordQuery = useListRecords(recordParams, {
    enabled: isRecordQueryEnabled,
  });

  const unenhancedItems = recordQuery?.data?.data ?? [];

  // This hook enhances record values with calculator_result configuration to have estimation_method_entity_id:
  const { recordOrRecords: items, isLoading: isEnhancing } =
    useEnhanceRecordResources(unenhancedItems, {
      enabled: isPropertyFilterEnabled,
    });

  const isLoading =
    (isRecordQueryEnabled && recordQuery.isLoading) ||
    (isPropertyFilterEnabled && isEnhancing);

  const applyCount =
    (Object.keys(selectedRecords).length || recordQuery.data?.total_entries) ??
    0;

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const recordReport = await RecordDomain.exportList(exportRecordParams);

      downloadLink(
        recordReport.s3_download_link,
        `Records - ${datetimeFormatter(new Date())}`
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsExporting(false);
    }
  };

  const unfilteredRecordValueTypes = Array.from(
    new Set(
      items?.flatMap(({ values }: RecordType) =>
        values.map(
          ({ measurement_type }: { measurement_type: string }) =>
            measurement_type
        )
      )
    )
  );

  useEffect(() => {
    setAvailableProperties?.(unfilteredRecordValueTypes);
  }, [unfilteredRecordValueTypes]);

  const recordValueTypes = isPropertyFilterEnabled
    ? [measurement_type_filter]
    : unfilteredRecordValueTypes;

  const flowHeaders = useFlowHeaders<RecordType>({
    prefix: "flow",
    keySubstitutions: {
      [FLOW_ATTRIBUTES.EQUIPMENT_NAME.key]: "flow_equipment_name",
      [FLOW_ATTRIBUTES.FACILITY_NAME.key]: "flow_facility_name",
    },
    sortSubstitutions: {
      [FLOW_ATTRIBUTES.EQUIPMENT.key]: "flow_equipment_name",
      [FLOW_ATTRIBUTES.FACILITY.key]: "flow_facility_name",
    },
  }).filter(
    ({ key }) => !["flow.product_category", "flow.product_type"].includes(key)
  );

  const getAssetHeaders = (
    assetType: AssetTypeType
  ): Array<HeaderType<RecordType>> => {
    switch (assetType) {
      case AssetType.FACILITY:
        return [
          {
            key: "facility.name",
            label: localize("Facility"),
            isSortable: true,
            renderComponent: ({ item }) => (
              <RoutingLink
                to={linkToAssetDetailPage(
                  AssetType.FACILITY,
                  item?.["facility.id"]
                )}
              >
                {item?.["facility.name"]}
              </RoutingLink>
            ),
          },
        ];
      case AssetType.EQUIPMENT:
        return [
          {
            key: "equipment.name",
            label: localize("Equipment"),
            isSortable: true,
            renderComponent: ({ item }) => (
              <RoutingLink
                to={linkToAssetDetailPage(
                  AssetType.EQUIPMENT,
                  item?.["equipment.id"]
                )}
              >
                {item?.["equipment.name"]}
              </RoutingLink>
            ),
          },
          {
            key: "equipment.status",
            label: `${localize("Equipment")} Status`,
            isSortable: true,
            renderComponent: ({ item }) => (
              <DataTable.DataRow.PillCell
                variant={
                  item?.["equipment.status"] === "active"
                    ? "success"
                    : "default"
                }
                value={toStartCaseString(item?.["equipment.status"])}
              />
            ),
          },
          {
            key: "equipment.type.name",
            label: localize("Equipment Type"),
            isSortable: true,
            renderComponent: ({ item }) => item?.["equipment.type.name"] ?? "-",
          },
          {
            key: "equipment_facility_name",
            label: localize("Facility"),
            isSortable: true,
            renderComponent: ({ item }) => (
              <RoutingLink
                to={linkToAssetDetailPage(
                  AssetType.FACILITY,
                  item?.equipment?.facility_id
                )}
              >
                {item?.equipment_facility_name}
              </RoutingLink>
            ),
          },
        ];
      case AssetType.FLOW:
      default:
        return flowHeaders;
    }
  };

  const applyAutomationFilters: DefaultConfigurationFilterType = Object.keys(
    selectedRecords
  ).length
    ? { id: Object.keys(selectedRecords) }
    : recordParams.filters ?? {};

  const handleApplyClick = () => {
    const count =
      (Object.keys(selectedRecords).length ||
        recordQuery.data?.total_entries) ??
      0;
    if (count > ASYNC_THRESHOLD) {
      setShowBulkApplyWarningWarning(true);
    } else {
      applyAutomation.mutate(applyAutomationFilters);
    }
  };

  const propertySpecificHeaders = useRecordMeasurementTypeSpecificTableHeader(
    measurement_type_filter
  );

  const { openDrawer, drawerState, navigationProps, onClose, recordValue } =
    useRecordValueDrawer({
      items,
      itemToAssetIdFunction: (i) =>
        i?.["flow.id"] ?? i?.["facility.id"] ?? i?.["equipment.id"],
    });

  const headers: Array<HeaderType<RecordType>> = [
    {
      key: "year_month",
      label: "Time Period",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToRecordDetail(
            type,
            item?.["flow.id"] ??
              item?.["facility.id"] ??
              item?.["equipment.id"],
            item.id
          )}
        >
          {monthFormatter(yearMonthParser(item?.year_month))}
        </RoutingLink>
      ),
    },
    ...getAssetHeaders(type),

    {
      key: "status",
      label: "Record Status",
      isSortable: false,
      renderComponent: ({ item }) => {
        const { values, id } = item;
        return (
          <InlineRecordValueStatusPicker
            recordId={id}
            values={values}
          />
        );
      },
    },

    ...recordValueTypes.map((key) => ({
      key,
      label: getTypeName(key),
      renderComponent: ({ item }) => {
        const { values, year_month, id } = item;
        const findValue = values?.find(
          ({ measurement_type }: { measurement_type: string }) =>
            measurement_type === key
        );

        return (
          <RecordValueCell
            value={findValue}
            onClick={() => {
              openDrawer(
                item?.["flow.id"] ??
                  item?.["facility.id"] ??
                  item?.["equipment.id"],
                id,
                year_month,
                key as string
              );
            }}
          />
        );
      },
    })),
    ...propertySpecificHeaders,
  ];
  const actionRow = [
    <BulkRecordStatusChangeButton
      key="status-picker"
      filter={
        Object.keys(selectedRecords).length > 0
          ? { id: Object.keys(selectedRecords) }
          : recordParams.filters ?? {}
      }
      recordsCount={
        Object.keys(selectedRecords).length > 0
          ? Object.keys(selectedRecords).length
          : recordQuery.data?.total_entries ?? 0
      }
    />,
    <Button
      key="apply-automation"
      isLoading={applyAutomation.isLoading || applyAutomationAsync.isLoading}
      onClick={handleApplyClick}
      disabled={!applyCount}
    >
      Apply Record Automation
    </Button>,
    <Button
      key="export-flow-records"
      variant="outline"
      onClick={() => {
        handleExport();
      }}
      isLoading={isExporting}
    >
      Export
    </Button>,
    ...(isDataIngestionEnabled
      ? [
          <ImportDataAction
            key={dataIngestionKey}
            resource={dataIngestionResource}
          />,
        ]
      : []),
  ];

  const getItemActions = ({ item }: { item: RecordType }) => {
    return [
      {
        label: "Apply Automation",
        buttonProps: {
          onClick: () => applyAutomation.mutate({ id: item.id }),
        },
      },
    ];
  };

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        actionRowWhenNoRowsSelected={actionRow}
        dataTableProps={{
          headers,
          items,
          isLoading,
          selected: selectedRecords,
          getItemId: (item) => item.id,
          onSelectionChange: setSelectedRecords,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          getItemActions,
          sorting,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: recordQuery.data?.total_entries ?? 0,
          },
        }}
        panelProps={{ title: "Records" }}
      />
      <AddRecordDialog
        isOpen={isAddDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={() => {
          recordQuery.refetch();
        }}
        assetType={type}
        reportingGroupId={reporting_group_id}
      />
      <Dialog
        key="initiate-large-apply-dialog"
        title="Initiate Apply Automation?"
        isOpen={showBulkApplyWarning}
        onClose={() => setShowBulkApplyWarningWarning(false)}
        actionRow={[
          <Button
            key="apply-automation-action"
            variant="primary"
            onClick={() => {
              applyAutomationAsync.mutate(applyAutomationFilters);
              setShowBulkApplyWarningWarning(false);
            }}
          >
            Apply Automation
          </Button>,
        ]}
      >
        You have selected to apply automations to all {applyCount}{" "}
        {Object.keys(selectedRecords).length ? "selected" : "filtered"} records.
        This may take some time.
      </Dialog>

      <RecordValueDrawer
        recordValue={recordValue}
        state={drawerState}
        onClose={onClose}
        assetType={type}
        applyAutomation={(id) => applyAutomation.mutate({ id })}
        navgivationProps={navigationProps}
      />
    </>
  );
};
