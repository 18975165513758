import {
  GetOneRequestType,
  GetOneResponseType,
  ResourceServiceType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";
import {
  TemplatedConfigurationRunRequestType,
  TemplatedConfigurationRunType,
} from "../schemas/TemplatedConfigurationRunSchemas";

type TemplatedConfigurationRunServiceType = Pick<
  ResourceServiceType<TemplatedConfigurationRunType>,
  "createOne" | "getList"
> & {
  getOne: (
    params: GetOneRequestType<{
      templated_configuration_name: string;
    }>
  ) => Promise<GetOneResponseType<TemplatedConfigurationRunType>>;
};

export const TemplatedConfigurationRunAdapter: TemplatedConfigurationRunServiceType =
  {
    /**
     * Create a single templated configuration run
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/create_templated_configuration_run
     */
    createOne: ({
      data: { templated_configuration_name, ...body },
    }: {
      data: TemplatedConfigurationRunRequestType & {
        templated_configuration_name: string;
      };
    }) =>
      restAPI.nodeAPI
        .POST<TemplatedConfigurationRunType>({
          endpoint: `/templated_configurations/${templated_configuration_name}/runs`,
          body,
        })
        .then((resp) => ({ data: resp })),

    /**
     * Get a single templated configuration run
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/get_templated_configuration_run
     */
    getOne: ({ id, meta: { templated_configuration_name } = {} }) => {
      if (!templated_configuration_name)
        return Promise.reject({
          success: false,
          error: "'templated_configuration_name' is required to fetch a run.",
        });

      return restAPI.nodeAPI
        .GET<TemplatedConfigurationRunType>({
          endpoint: `/templated_configurations/${templated_configuration_name}/runs/${id}`,
        })
        .then((resp) => ({ data: resp }));
    },
    /**
     * Get the list of templated configuration runs by searching
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/templated_configurations/search_templated_configuration_runs
     */
    getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
      fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST({
            endpoint: "/templated_configurations/runs/search",
            body: {
              sort_by: sortBy,
              sort_direction: sortDirection,
              page,
              page_size: pageSize,
              filter: getFilterObject(filters),
            },
          }),
        page,
        pageSize
      ),
  };
