import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useSearchParams } from "#routers/hooks";
import useDeviceTypes from "#src/components/hooks/useDeviceTypes";
import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import { EQUIPMENT_STATUS_OPTIONS } from "#src/constants";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  ContentSwitcherInput,
  DropdownInput,
  FilterPanel,
  TextInput,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import React from "react";

const EntityFilterPanel = () => {
  const { localize } = useLocalization();
  const [isDevicesFeatureAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:devices",
    permissionQuery: "devices:read",
  });
  const [isFlowsFeatureAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:flows",
    permissionQuery: "flows:read",
  });

  const [searchParams, setSearchParam] = useSearchParams();
  const [deviceTypes] = useDeviceTypes();
  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];

  const assetType = searchParams.section ?? AssetType.EQUIPMENT;

  const { customAttributeFilters } = useCustomAttributeFilters({
    assetType,
  });

  const onChange = async (newValue) => {
    const { type, custom_attributes, section, ...rest } = newValue;

    let returnValue = {
      ...rest,
      custom_attributes,
      ...(type?.id ? { "type.id": type.id } : {}),
      // Don't clear the section when applying empty filters
      section: section ?? assetType,
    };

    // remove all queryparams if entity change
    if (searchParams.section !== returnValue.section) {
      returnValue = { section: returnValue.section };
    }

    setSearchParam(
      toFlattenedObject(returnValue, { transformObjectValue: (val) => val?.id })
    );
  };

  const onReset = (newFormValues) => {
    setSearchParam({ ...newFormValues, section: searchParams.section });
  };

  const deviceFilters = [
    {
      section: "Filters",
      component: (
        <DropdownInput
          label="Device Type"
          name="type_id"
          labelKey="name"
          valueKey="id"
          options={deviceTypes}
          isFluid
          isMulti
          isSearchable
          isOptionalTextShown={false}
          key="type"
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <DropdownInput
          label="Status"
          name="status"
          options={[
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
          ]}
          labelKey="label"
          valueKey="value"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
          key="status"
        />
      ),
    },
  ];

  const equipmentFilters = [
    {
      section: "Filters",
      component: (
        <DropdownInput
          label={`${localize("Equipment")} Status`}
          name="status"
          options={EQUIPMENT_STATUS_OPTIONS}
          labelKey="label"
          valueKey="value"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <DropdownInput
          label={`${localize("Equipment")} Type`}
          name="type.id"
          options={equipmentTypes}
          labelKey="name"
          valueKey="id"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
  ];

  const filterMapping = {
    [AssetType.EQUIPMENT]: equipmentFilters,
    [AssetType.DEVICE]: deviceFilters,
  };

  const showItems = () => {
    const items = [
      {
        label: localize("equipment_plural"),
        dataKey: AssetType.EQUIPMENT,
      },
    ];

    if (isDevicesFeatureAvailable) {
      items.push({ label: "Devices", dataKey: AssetType.DEVICE });
    }

    if (isFlowsFeatureAvailable) {
      items.push({ label: localize("flow_plural"), dataKey: AssetType.FLOW });
    }

    return items;
  };

  return (
    <FilterPanel
      defaultActiveKeys={["Filters"]}
      onChange={onChange}
      value={searchParams}
      onReset={onReset}
      filters={[
        {
          component: (
            <ContentSwitcherInput
              name="section"
              key="filter-content-section"
              items={showItems()}
              isInline
            />
          ),
        },
        {
          component: (
            <TextInput
              name="name"
              placeholder="Search by Name..."
              type="search"
              isInline
              key="filter-name"
            />
          ),
        },
        ...(filterMapping[searchParams.section] ?? []),
        ...customAttributeFilters,
      ]}
    />
  );
};

export default EntityFilterPanel;
