import { z } from "zod";

export const Resources = {
  ACTIVITY: "activity",
  ASSET_GROUP: "asset_group",
  CALCULATOR_RESULT: "calculator_result",
  CHARTS: "chart",
  COMPANY_UNIT_CONFIG: "company_unit_config",
  COMPANY: "company",
  USER_UNIT_CONFIG: "user_unit_config",
  CUSTOM_ATTRIBUTE_DEFINITION: "custom_attribute_definition",
  CUSTOM_REPORTS: "custom_reports",
  COMPANY_CUSTOM_REPORTS: "company_custom_reports",
  DASHBOARD: "dashboard",
  DASHBOARDS_INSIGHTS: "dashboards_insights",
  DEVICE: "device",
  DEFAULT_RECORD_VALUE_CONFIGURATION: "default_record_value_configuration",
  EMISSIONS: "emissions",
  EQUIPMENT: "equipment",
  EQUIPMENT_TYPE: "equipment_type",
  ESTIMATION_METHOD: "estimation_method",
  DEFAULT_CALCULATOR_INPUT_RECORD: "default_calculator_input_record",
  EVENT_CATEGORY: "event_category",
  EVENT_SCHEMA: "event_schema",
  EVENT: "event",
  FACILITY: "facility",
  FLOW: "flow",
  FORM_CATEGORY: "form_category",
  FORM_SCHEMA: "form_schema",
  FORM_SUBMISSION: "form_submission",
  PERMISSION: "permission",
  MEASUREMENT_SERIES: "measurement_series",
  MEASUREMENT_TYPE: "measurement_type",
  MEASUREMENT: "measurement",
  NETWORK: "network",
  REPORT: "report",
  REPORTING_GROUP: "reporting_group",
  TEMPLATED_CONFIGURATION: "templated_configuration",
  TEMPLATED_CONFIGURATION_RUN: "templated_configuration_run",
  TEMPLATED_REPORT: "templated_report",
  RECORD: "record",
  RECORD_VALUE: "record_value",
  ROLE: "role",
  USER: "user",
  USER_GROUP: "user_group",
  USER_GROUP_CATEGORY: "user_group_category",
  VOLUMETRIC_REPORTING: "volumetric_reporting",
  WORKFLOW_CATEGORY: "workflow_category",
  WORKFLOW_TEMPLATE: "workflow_template",
  WORKFLOW: "workflow",
  WORKFLOW_TASK: "workflow_task",
  FEATURE_FLAG: "feature_flag",
  COMPANY_FEATURE_FLAG: "company_feature_flag",
  JOB: "job",

  LEDGER: "ledger",
  PROJECT_GROUP: "project_group",
  CREDIT_OWNER: "credit_owner",

  DATASET: "dataset",
  TEMPLATE: "template",
  TRANSACTION: "transaction",

  CALCULATOR: "calculator",
  LIBRARY: "library",
} as const;

export type ResourceType = (typeof Resources)[keyof typeof Resources];

export type ResourceDefinitionType = {
  id: ResourceType;
  description: string;
  label: {
    singular: string;
    plural: string;
  };
  derivedFrom?: ResourceType;
};

export const ResourceDefinitions: Record<ResourceType, ResourceDefinitionType> =
  {
    [Resources.ACTIVITY]: {
      id: Resources.ACTIVITY,
      description:
        "A stream of change events for a particular feature in the app",
      label: {
        singular: "Change Log",
        plural: "Change Logs",
      },
    },
    [Resources.ASSET_GROUP]: {
      id: Resources.ASSET_GROUP,
      description: "An asset which contains many other assets",
      label: {
        singular: "Asset Group",
        plural: "Asset Groups",
      },
    },
    [Resources.CALCULATOR_RESULT]: {
      id: Resources.CALCULATOR_RESULT,
      description: "A measurement-shaped output from a calculator run",
      label: {
        singular: "Calculator Result",
        plural: "Calculator Results",
      },
    },
    [Resources.CHARTS]: {
      id: Resources.CHARTS,
      description: "Configuration for a chart within a custom dashboard",
      label: {
        singular: "Chart",
        plural: "Charts",
      },
    },
    [Resources.COMPANY_UNIT_CONFIG]: {
      id: Resources.COMPANY_UNIT_CONFIG,
      description:
        "Company-level configuration for measurement type display preferences",
      label: {
        singular: "Company Unit Configuration",
        plural: "Company Unit Configurations",
      },
    },
    [Resources.COMPANY]: {
      id: Resources.COMPANY,
      description: "A group of users that share common assets and data",
      label: {
        singular: "Company",
        plural: "Companies",
      },
    },
    [Resources.USER_UNIT_CONFIG]: {
      id: Resources.USER_UNIT_CONFIG,
      description:
        "User-level configuration for measurement type display preferences",
      label: {
        singular: "User Unit Configuration",
        plural: "User Unit Configurations",
      },
    },
    [Resources.CUSTOM_ATTRIBUTE_DEFINITION]: {
      id: Resources.CUSTOM_ATTRIBUTE_DEFINITION,
      description:
        "Shape of a company-specific attribute that can be added to an asset",
      label: {
        singular: "Custom Attribute",
        plural: "Custom Attributes",
      },
    },
    [Resources.CUSTOM_REPORTS]: {
      id: Resources.CUSTOM_ATTRIBUTE_DEFINITION,
      description: "Pre-configured saved views on emissions or volume data",
      label: {
        singular: "Custom Report",
        plural: "Custom Reports",
      },
      derivedFrom: Resources.REPORT,
    },
    [Resources.COMPANY_CUSTOM_REPORTS]: {
      id: Resources.CUSTOM_ATTRIBUTE_DEFINITION,
      description:
        "Pre-configured saved views on emissions or volume data at a company-level",
      label: {
        singular: "Company Custom Report",
        plural: "Company Custom Reports",
      },
      derivedFrom: Resources.REPORT,
    },
    [Resources.VOLUMETRIC_REPORTING]: {
      id: Resources.CUSTOM_ATTRIBUTE_DEFINITION,
      description: "Custom reports on volume data",
      label: {
        singular: "Volumetric Report",
        plural: "Volumetric Reports",
      },
      derivedFrom: Resources.REPORT,
    },
    [Resources.DASHBOARD]: {
      id: Resources.DASHBOARD,
      description:
        "Configuration for a custom dashboard which is a collection of charts",
      label: {
        singular: "Dashboard",
        plural: "Dashboards",
      },
    },
    [Resources.DASHBOARDS_INSIGHTS]: {
      id: Resources.DASHBOARDS_INSIGHTS,
      description:
        "Configuration for a custom dashboard which is a collection of charts meant to provide insights",
      label: {
        singular: "Insights Dashboard",
        plural: "Insights Dashboards",
      },
      derivedFrom: Resources.DASHBOARD,
    },
    [Resources.DEVICE]: {
      id: Resources.DEVICE,
      description: "An asset that is the source of measurements",
      label: {
        singular: "Device",
        plural: "Devices",
      },
    },
    [Resources.DEFAULT_RECORD_VALUE_CONFIGURATION]: {
      id: Resources.DEFAULT_RECORD_VALUE_CONFIGURATION,
      description: "The recipe for creating new record values on an asset",
      label: {
        singular: "Default Record Value Configuration",
        plural: "Default Record Value Configurations",
      },
    },
    [Resources.EMISSIONS]: {
      id: Resources.EMISSIONS,
      description: "An amount of a type on an equipment at a particular time",
      label: {
        singular: "Emission",
        plural: "Emissions",
      },
    },
    [Resources.EQUIPMENT]: {
      id: Resources.EQUIPMENT,
      description: "An asset that produces emissions",
      label: {
        singular: "Equipment",
        plural: "Equipment",
      },
    },
    [Resources.EQUIPMENT_TYPE]: {
      id: Resources.EQUIPMENT_TYPE,
      description: "A type definition on equipment asset",
      label: {
        singular: "Equipment Type",
        plural: "Equipment Types",
      },
    },
    [Resources.ESTIMATION_METHOD]: {
      id: Resources.ESTIMATION_METHOD,
      description: "Configuration for calculating properties on an asset",
      label: {
        singular: "Estimation Method",
        plural: "Estimation Methods",
      },
    },
    [Resources.DEFAULT_CALCULATOR_INPUT_RECORD]: {
      id: Resources.DEFAULT_CALCULATOR_INPUT_RECORD,
      description: "Recipe for sourcing calculator inputs from asset records",
      label: {
        singular: "Default Calculator Input Record",
        plural: "Default Calculator Input Records",
      },
    },
    [Resources.EVENT_CATEGORY]: {
      id: Resources.EVENT_CATEGORY,
      description: "A group of Event Schemas",
      label: {
        singular: "Event Category",
        plural: "Event Categories",
      },
    },
    [Resources.EVENT_SCHEMA]: {
      id: Resources.EVENT_SCHEMA,
      description: "A set of rules for creating events",
      label: {
        singular: "Event Template",
        plural: "Event Templates",
      },
    },
    [Resources.EVENT]: {
      id: Resources.EVENT,
      description: "A temporal event that can contain assets and measurements",
      label: {
        singular: "Event",
        plural: "Events",
      },
    },
    [Resources.FACILITY]: {
      id: Resources.FACILITY,
      description:
        "An asset that groups equipment, flows and devices geographically",
      label: {
        singular: "Facility",
        plural: "Facilities",
      },
    },
    [Resources.FLOW]: {
      id: Resources.FLOW,
      description:
        "An asset that represents the transfer of a physical quantity",
      label: {
        singular: "Flow",
        plural: "Flows",
      },
    },
    [Resources.FORM_CATEGORY]: {
      id: Resources.FORM_CATEGORY,
      description: "A group of Form Schemas",
      label: {
        singular: "Form Category",
        plural: "Form Categories",
      },
    },
    [Resources.FORM_SCHEMA]: {
      id: Resources.FORM_SCHEMA,
      description: "A set of rules for creating form submissions",
      label: {
        singular: "Form Template",
        plural: "Form Templates",
      },
    },
    [Resources.FORM_SUBMISSION]: {
      id: Resources.FORM_SUBMISSION,
      description: "A payload that conforms to a form schema",
      label: {
        singular: "Form Submission",
        plural: "Form Submissions",
      },
    },
    [Resources.PERMISSION]: {
      id: Resources.PERMISSION,
      description:
        "Access to perform read or write actions on a set of resources",
      label: {
        singular: "Permission",
        plural: "Permissions",
      },
    },
    [Resources.MEASUREMENT_SERIES]: {
      id: Resources.MEASUREMENT_SERIES,
      description:
        "A reference to a time-series table containing measurements of a single type",
      label: {
        singular: "Measurement Series",
        plural: "Measurement Series",
      },
    },
    [Resources.MEASUREMENT_TYPE]: {
      id: Resources.MEASUREMENT_TYPE,
      description: "Physical quantity that can be measured",
      label: {
        singular: "Measurement Type",
        plural: "Measurement Types",
      },
    },
    [Resources.MEASUREMENT]: {
      id: Resources.MEASUREMENT,
      description: "Numeric value/unit/type/time tuple",
      label: {
        singular: "Measurement",
        plural: "Measurements",
      },
    },
    [Resources.NETWORK]: {
      id: Resources.NETWORK,
      description:
        "A representation of flows across facilities and how they interact",
      label: {
        singular: "Network",
        plural: "Networks",
      },
      derivedFrom: Resources.ASSET_GROUP,
    },
    [Resources.REPORT]: {
      id: Resources.REPORT,
      description: "Instance of a template filled in with company data",
      label: {
        singular: "Report",
        plural: "Reports",
      },
    },
    [Resources.REPORTING_GROUP]: {
      id: Resources.REPORTING_GROUP,
      description:
        "Discriminating field for grouping measurements, records or calculations",
      label: {
        singular: "Reporting Scenario",
        plural: "Reporting Scenarios",
      },
    },
    [Resources.TEMPLATED_CONFIGURATION]: {
      id: Resources.TEMPLATED_CONFIGURATION,
      description:
        "A set of instructions for creating a specific resource with all its related resources",
      label: {
        singular: "Templated Configuration",
        plural: "Templated Configurations",
      },
    },
    [Resources.TEMPLATED_CONFIGURATION_RUN]: {
      id: Resources.TEMPLATED_CONFIGURATION_RUN,
      description:
        "An async job for creating a set of specific resource based on a defined template (templated configuration)",
      label: {
        singular: "Templated Configuration Run",
        plural: "Templated Configuration Runs",
      },
    },
    [Resources.TEMPLATED_REPORT]: {
      id: Resources.TEMPLATED_REPORT,
      description:
        "A script that can fill company data into a template, generating a report",
      label: {
        singular: "Templated Report",
        plural: "Templated Reports",
      },
      derivedFrom: Resources.REPORT,
    },
    [Resources.RECORD]: {
      id: Resources.RECORD,
      description:
        "The amount of a type on an asset at a time in a reporting group",
      label: {
        singular: "Record",
        plural: "Records",
      },
    },
    [Resources.RECORD_VALUE]: {
      id: Resources.RECORD_VALUE,
      description:
        "The detailed measured amount of a type on an asset at a time in a reporting group",
      label: {
        singular: "Record value",
        plural: "Record values",
      },
    },
    [Resources.ROLE]: {
      id: Resources.USER,
      description:
        "A role is a set of permissions that can be assigned to users",
      label: {
        singular: "Role",
        plural: "Roles",
      },
    },
    [Resources.USER]: {
      id: Resources.USER,
      description:
        "A user which can interact with the product on behalf of a company",
      label: {
        singular: "User",
        plural: "Users",
      },
    },
    [Resources.USER_GROUP]: {
      id: Resources.USER_GROUP,
      description: "A group of users",
      label: {
        singular: "User Group",
        plural: "User Groups",
      },
    },
    [Resources.USER_GROUP_CATEGORY]: {
      id: Resources.USER_GROUP_CATEGORY,
      description: "A group of user groups",
      label: {
        singular: "User Group Category",
        plural: "User Group Categories",
      },
    },
    [Resources.WORKFLOW_CATEGORY]: {
      id: Resources.WORKFLOW_CATEGORY,
      description: "Grouping of workflow templates",
      label: {
        singular: "Workflow Category",
        plural: "Workflow Categories",
      },
    },
    [Resources.WORKFLOW_TEMPLATE]: {
      id: Resources.WORKFLOW_TEMPLATE,
      description: "Set of rules for creating workflows",
      label: {
        singular: "Workflow Template",
        plural: "Workflow Templates",
      },
    },
    [Resources.WORKFLOW]: {
      id: Resources.WORKFLOW,
      description: "An instance of a workflow template",
      label: {
        singular: "Workflow",
        plural: "Workflows",
      },
    },
    [Resources.WORKFLOW_TASK]: {
      id: Resources.WORKFLOW_TASK,
      description: "A part of a workflow that can be completed by a user",
      label: {
        singular: "Workflow Task",
        plural: "Workflow Tasks",
      },
    },
    [Resources.FEATURE_FLAG]: {
      id: Resources.FEATURE_FLAG,
      description: "Toggle that enables or disables sections of the app",
      label: {
        singular: "Feature Flag",
        plural: "Feature Flags",
      },
    },
    [Resources.COMPANY_FEATURE_FLAG]: {
      id: Resources.COMPANY_FEATURE_FLAG,
      description: "Company configuration of a feature flag toggle",
      label: {
        singular: "Company Feature Flag",
        plural: "Company Feature Flags",
      },
    },
    [Resources.JOB]: {
      id: Resources.JOB,
      description: "An asynchronous task executing in Carbon Hub",
      label: {
        singular: "Job",
        plural: "Jobs",
      },
    },
    [Resources.LEDGER]: {
      id: Resources.LEDGER,
      description:
        "An instance of credit allocation in a project group for a period",
      label: {
        singular: "Ledger Entry",
        plural: "Ledger Entries",
      },
    },
    [Resources.PROJECT_GROUP]: {
      id: Resources.PROJECT_GROUP,
      description:
        "A collection of ledgers that correspond to the same project",
      label: {
        singular: "Project Group",
        plural: "Project Groups",
      },
    },
    [Resources.CREDIT_OWNER]: {
      id: Resources.CREDIT_OWNER,
      description: "The holder of ledger credits",
      label: {
        singular: "Credit Owner",
        plural: "Credit Owners",
      },
    },
    [Resources.DATASET]: {
      id: Resources.DATASET,
      description: "A resource that can be imported",
      label: {
        singular: "Dataset",
        plural: "Datasets",
      },
    },
    [Resources.TEMPLATE]: {
      id: Resources.TEMPLATE,
      description: "The allowed pattern for a resource to be imported",
      label: {
        singular: "Template",
        plural: "Templates",
      },
    },
    [Resources.TRANSACTION]: {
      id: Resources.TRANSACTION,
      description: "A record of an imported resource",
      label: {
        singular: "Data Submission",
        plural: "Data Submissions",
      },
    },
    [Resources.CALCULATOR]: {
      id: Resources.CALCULATOR,
      description: "A python script that returns calculator result outputs",
      label: {
        singular: "Calculator",
        plural: "Calculators",
      },
    },
    [Resources.LIBRARY]: {
      id: Resources.LIBRARY,
      description: "Logical grouping of calculators",
      label: {
        singular: "Library",
        plural: "Libraries",
      },
    },
  };

const [firstKey, ...otherKeys] = Object.values(Resources);
export const ResourceZodSchema = z.enum([firstKey, ...otherKeys]);
