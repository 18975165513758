import { EquipmentDropdownInput } from "#batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import { FlowDropdownInput } from "#batteries-included-components/Dropdowns/FlowDropdownInput";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useListEquipmentTypes } from "#hooks/adapters/useEquipment";
import { useListFlowTypes } from "#hooks/adapters/useFlows";
import { useListCalculatorResults } from "#src/components/hooks/adapters/useCalculatorResults";
import { useListCalculators } from "#src/components/hooks/adapters/useCalculators";
import { EQUIPMENT_STATUS_OPTIONS } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import {
  DropdownInput,
  FilterPanel,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { AssetType, AssetTypeType, FlowStatus } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useEffect, useMemo } from "react";

export const EstimationMethodListFilterPanel = ({
  filterConfigStorageKey,
  assetType,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  assetType: AssetTypeType;
}) => {
  const { localize } = useLocalization();
  const [_, setFilters] = useFilters<any>(filterConfigStorageKey);

  useEffect(() => {
    // Clear filters on assetType change
    setFilters({});
  }, [assetType]);

  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];
  const flowTypes = useListFlowTypes()?.data ?? [];
  const calculatorQuery = useListCalculators();
  const validCalculatorsQuery = useListCalculatorResults({
    page: 1,
    pageSize: 500,
    filters: {
      entity_type: { $exact: assetType },
    },
    groupBy: ["estimation_method.analytics_calculator_id"],
  });

  const validCalculatorIds =
    validCalculatorsQuery.data?.data.map(
      (item) => item["estimation_method.analytics_calculator_id"]
    ) ?? [];

  const calculatorOptions = useMemo(
    () =>
      calculatorQuery.data?.calculators && validCalculatorIds.length
        ? calculatorQuery.data.calculators
            .map((calculator) => ({
              value: calculator.id,
              label:
                calculator.versions.find(
                  (version) => version.version === calculator.default_version
                )?.title ?? calculator.id,
            }))
            .filter(({ value }) => validCalculatorIds.includes(value))
        : [],
    [calculatorQuery.data, validCalculatorIds]
  );

  const { customAttributeFilters: facilityFilters } = useCustomAttributeFilters(
    {
      assetType: AssetType.FACILITY,
      section: localize("Facility"),
      prefix:
        assetType === AssetType.FLOW
          ? "flow.facility.custom_attributes"
          : "equipment.facility.custom_attributes",
    }
  );

  const { customAttributeFilters: equipmentFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.EQUIPMENT,
      section: localize("Equipment"),
      prefix:
        assetType === AssetType.FLOW
          ? "flow.equipment.custom_attributes"
          : "equipment.custom_attributes",
    });

  const { customAttributeFilters: flowFilters } = useCustomAttributeFilters({
    assetType: AssetType.FLOW,
    section: "Flow",
    prefix: "flow.custom_attributes",
  });

  const equipmentOnlyFilter = [
    {
      section: localize("Equipment"),
      component: (
        <EquipmentDropdownInput
          isFluid
          name="equipment.id"
          key="equipment.id"
        />
      ),
    },
    {
      section: localize("Equipment"),
      component: (
        <DropdownInput
          label="Equipment Type"
          isOptionalTextShown={false}
          options={equipmentTypes}
          labelKey="name"
          valueKey="id"
          name="equipment.type.id"
          key="equipment.type.id"
          isMulti
        />
      ),
    },
    {
      section: localize("Equipment"),
      component: (
        <DropdownInput
          label="Equipment Status"
          isOptionalTextShown={false}
          options={EQUIPMENT_STATUS_OPTIONS}
          labelKey="label"
          valueKey="value"
          name="equipment.status"
          key="equipment.status"
          isMulti
        />
      ),
    },
  ];

  const flowOnlyFilters = [
    {
      section: "Flow",
      component: (
        <FlowDropdownInput
          isFluid
          name="flow.id"
          key="flow.id"
        />
      ),
    },
    {
      section: "Flow",
      component: (
        <DropdownInput
          label="Flow Type"
          isOptionalTextShown={false}
          options={flowTypes}
          labelKey="name"
          valueKey="id"
          key="flow.type"
          name="flow.type"
          isMulti
        />
      ),
    },
    {
      section: "Flow",
      component: (
        <DropdownInput
          key="flow.status"
          name="flow.status"
          label="Flow Status"
          options={Object.values(FlowStatus).map((id) => ({
            id,
            name: toStartCaseString(id),
          }))}
          labelKey="name"
          valueKey="id"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
  ];

  return (
    <FilterPanel
      defaultActiveKeys={[
        "Estimation Method",
        localize("Facility"),
        localize("Equipment"),
        "Flow",
      ]}
      storageKey={filterConfigStorageKey}
      filters={[
        {
          section: "Estimation Method",
          component: (
            <DropdownInput
              key="analytics_calculator_id"
              name="analytics_calculator_id"
              label="Calculator Name"
              options={calculatorOptions}
              labelKey="label"
              valueKey="value"
              isLoading={calculatorQuery.isLoading}
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: localize("Facility"),
          component: (
            <FacilityDropdownInput
              isFluid
              name={
                assetType === AssetType.FLOW
                  ? "flow.associated_facility_id"
                  : "equipment.facility.id"
              }
              key="equipment.facility.id"
            />
          ),
        },
        ...facilityFilters,
        ...(assetType === AssetType.EQUIPMENT
          ? [...equipmentOnlyFilter, ...equipmentFilters]
          : []),
        ...(assetType === AssetType.FLOW
          ? [...flowOnlyFilters, ...flowFilters]
          : []),
      ]}
    />
  );
};
